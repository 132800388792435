import { RefObject, useEffect } from "react";

interface UseOnClickAwayProps {
    /* event to be listened */
    eventName?: "mousedown" | "click" | "mouseup";
    /* ref associated element to be listened */
    ref: RefObject<any>;
    /* callback */
    onClickAway: (event: Event) => void;
    activated?: boolean;
}

export function useOnClickAway(props: UseOnClickAwayProps): void {
  const {
    activated = true,
    eventName = "mousedown",
    ref,
    onClickAway
  } = props;
  useEffect(() => {
    if (activated) {
      const listener = (event: Event) => {
        // Do nothing if clicking refs element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        onClickAway(event);
      };
      document.addEventListener(eventName, listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener(eventName, listener);
        document.removeEventListener("touchstart", listener);
      };
    }
  }, [activated, ref, onClickAway, eventName]);
}