import { flatten, uniq } from "lodash";
import { computed } from "mobx";
import { computedAsync } from "computed-async-mobx";

import { SectionableProductStore } from "../Sections/SectionableProductStore";
import { SingleSectionStore } from "../Sections/SingleSection";
import { Product } from "../Product/product";
import { IProduct, UNGROUPED_ATTRIBUTE_SECTION } from "../Product/types";
// import { IBusinessObjectsClient } from "./BusinessObjects/query/businessObjectsClient";
import { ISection, IUngroupedSection, Members } from "../Sections/types";
import { IViewerClient } from "./client/client";
import { IEditClient } from "../ProductEditor/client/client";

// Note commented code is related with business object, this feature is currently disabled

export interface IProductViewerStore {
  sections: ISection[] | undefined;
  members: Array<Members> | undefined;
  ungroupedSection?: IUngroupedSection;
  isEmptyProduct: boolean;
  isProductLoading: boolean;
  product?: IProduct;
  isProductFound: boolean;
  sectionsWithoutBusinessObjects: ISection[];
  isEditable: boolean;
}

export interface ProductViewerStoreProps {
  client: IViewerClient;
  // businessObjectsClient?: IBusinessObjectsClient;
  editorClient: IEditClient;
  isEditable: boolean;
}

// type GroupedSections = { [key: string]: ISection };
export class InvalidConfigError extends Error {}

export class ProductViewerStore implements IProductViewerStore {
  client: IViewerClient;
  isEditable: boolean;

  constructor(private props: ProductViewerStoreProps) {
    this.isEditable = props.isEditable;
  }

  fetchedProduct = computedAsync(undefined, () => {
    return this.props.client.fetchProduct();
  });

  @computed get product() {
    return this.unprocessedProduct && this.unprocessedProduct.isProcessing
      ? undefined
      : this.unprocessedProduct && this.unprocessedProduct.isProcessing === false
      ? this.unprocessedProduct
      : undefined;
  }

  @computed get unprocessedProduct() {
    const { value } = this.fetchedProduct;

    return value && this.isProductFound
      ? new Product(value.data.product, this.props.client.batchAttributeGroup, this.props.editorClient.fetchProduct, this.props.isEditable)
      : undefined;
  }

  @computed get isProductFound() {
    const { value } = this.fetchedProduct;
    return value && value.data.product && value.data.product.stepId ? true : false;
  }

  @computed
  get isProductLoading() {
    return this.fetchedProduct.busy || (this.unprocessedProduct && this.unprocessedProduct.isProcessing) ? true : false;
  }

  @computed get sectionableDetailStore() {
    if (this.product) {
      const { attributeGroups, primaryImage, productImages } = this.product;
      return new SectionableProductStore({ attributeGroups, primaryImage, productImages });
    }
    return undefined;
  }

  @computed
  get sections() {
    return this.sectionableDetailStore && this.sectionableDetailStore.sortedSections.filter(section => !section.isEmpty);
  }

  @computed
  get sectionsWithoutBusinessObjects() {
    return (this.sectionableDetailStore && this.sectionableDetailStore.sortedSections) || [];
  }

  @computed
  get ungroupedSection() {
    return this.sectionableDetailStore && this.sectionableDetailStore.ungroupedSection;
  }

  @computed get attributeMembers() {
    const sectionableDetailStore = this.sectionableDetailStore;
    const sortedSections = sectionableDetailStore ? sectionableDetailStore.sortedSections : [];
    return sortedSections.length > 0 ? [] : this.singleSectionMemberWithImages();
  }

  get members() {
    return (
      // (this.groupedBusinessObjects &&
      //   (this.groupedBusinessObjects.members as Array<Members>) // todo remove casting
      //     .concat(this.attributeMembers)) ||
      this.attributeMembers
    );
  }

  @computed
  get isEmptyProduct(): boolean {
    if (this.product) {
      const { primaryImage, attributeGroups } = this.product;

      const isWithoutPrimaryImage = primaryImage ? false : true;
      const isEmpty = attributeGroups.length === 0 || !attributeGroups.some(group => group.hasAttributes());

      return isEmpty ? isEmpty && isWithoutPrimaryImage : false;
    }
    return true;
    // return !this.businessObjectsSections.length && this.groupedBusinessObjects && !this.groupedBusinessObjects.members.length ? true : false;
  }

  private singleSectionMemberWithImages(): Array<Members> {
    if (this.product) {
      const { topAttributeGroup, primaryImage, productImages } = this.product;

      const singleSection = new SingleSectionStore({
        name: "",
        order: 0,
        attributeGroups: flatten([
          topAttributeGroup && topAttributeGroup.hasAttributes() ? topAttributeGroup : undefined,
          topAttributeGroup && topAttributeGroup.childGroups
        ]).filter(group => group)
      });
      singleSection.createCarousel(primaryImage, productImages);
      return singleSection.members;
    }
    return [];
  }

  // businessObjects = computedAsync(undefined, () => {
  //   return this.props.businessObjectsClient ? this.props.businessObjectsClient.getBusinessObjects() : undefined;
  // });

  // @computed
  // get groupedBusinessObjects() {
  //   if (this.businessObjects.value) {
  //     const processedQuery = this.businessObjectsQueryToProps(this.businessObjects.value.data);
  //     const groupedSections = this.groupBusinessObjects(processedQuery);
  //     try {
  //       this.sectionableDetailStore && this.checkSectionsConflict(groupedSections.sections, this.sectionableDetailStore.sortedSections);
  //       this.checkMembersConflict(groupedSections.members, this.attributeMembers);
  //     } catch (error) {
  //       console.log(error);
  //       return undefined;
  //     }
  //     return groupedSections;
  //   }
  //   return undefined;
  // }

  // @computed get businessObjectsSections() {
  //   return (this.groupedBusinessObjects && Object.values(this.groupedBusinessObjects.sections)) || [];
  // }

  // private checkSectionsConflict(groupedSections: GroupedSections, sections?: Array<ISection>) {
  //   if (!sections) {
  //     return;
  //   }
  //   sections.forEach(section => {
  //     if (groupedSections.hasOwnProperty(section.name)) {
  //       throw new InvalidConfigError(`Section "${section.name}" must not mix Business Objects and other type of data`);
  //     }
  //   });
  // }

  // private checkMembersConflict(boMembers: Array<BusinessObjectMember>, otherMembers?: Array<any>) {
  //   if (!otherMembers) {
  //     return;
  //   }

  //   if (boMembers.length && otherMembers.length) {
  //     throw new InvalidConfigError(`Some business objects are not assigned to Sections.`);
  //   }
  // }

  // private groupBusinessObjects = (objects: Array<BusinessObjectRoot>): GroupedBusinessObjects => {
  //   return objects.reduce(
  //     ({ sections, members }, root: BusinessObjectRoot) => {
  //       const name = root.section.name;
  //       const member = new BusinessObjectMember(root);

  //       if (!name) {
  //         return {
  //           sections,
  //           members: [...members, member]
  //         };
  //       }

  //       const section: ISection = sections.hasOwnProperty(name) ? sections[name] : { ...root.section, members: [], noMasonry: true };
  //       section.members.push(member);

  //       return {
  //         members,
  //         sections: {
  //           ...sections,
  //           [name]: section
  //         }
  //       };
  //     },
  //     { sections: {} as GroupedSections, members: [] }
  //   );
  // };

  // private businessObjectsQueryToProps = (rawData?: BusinessObjectsQueryResult): Array<BusinessObjectRoot> => {
  //   return get(rawData, "businessObjects", []).map(this.businessObjectRootFragmentToProps);
  // };

  // private businessObjectRootFragmentToProps = (rawData?: BusinessObjectRootFragment): BusinessObjectRoot => {
  //   const attributes = new Map();
  //   return {
  //     section: {
  //       name: get(rawData, "section.simpleValue", ""),
  //       order: parseInt(get(rawData, "section.valueStepId", 99999), 10)
  //     },
  //     attributes,
  //     object: this.businessObjectFragmentToProps(get(rawData, "businessObject"), attributes, false)
  //   };
  // };

  // private businessObjectFragmentToProps = (fragment: BusinessObjectFragment, attributes: BusinessObjectChildAttributes, collect = true): BusinessObject => {
  //   const data = {
  //     id: get(fragment, "sourceNode.stepId", ""),
  //     source: get(fragment, "sourceNode"),
  //     values: this.rawDataToBOValues(fragment, collect ? attributes : undefined),
  //     title: get(fragment, "childObjects[0].title", ""),
  //     hasChildren: get(fragment, "childObjects[0].count", 0) > 0,
  //     children: get(fragment, "childObjects[0].businessObjects", []).map(b => this.businessObjectFragmentToProps(b, attributes))
  //   };
  //   return data;
  // };

  // private rawDataToBOValues = (rawData: BusinessObjectFragment, attributes?: BusinessObjectChildAttributes): Array<ValueNodeFragment> => {
  //   return get(rawData, "values.edges", []).map(({ node }) => {
  //     // WARNING: introducing side effect to reduce complexity
  //     if (attributes) {
  //       const typedNode = node as ValueNodeFragment;
  //       attributes.set(typedNode.attribute.stepId, typedNode.attribute);
  //     }
  //     return node;
  //   });
  // };
}
