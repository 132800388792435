import { createStyles, StandardProps } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { Card, withStiboStyles, WithStiboStyles } from "tim-ui";
import { BrokenImageIcon } from "../icons/brokenImageIcon/BrokenImageIcon";
import { EmptyImage, Image } from "../screen/ProductDetailsScreen/Product/types";
import EmptyImageMessage from "./EmptyImageMessage";

export type ProductImageClasses = "card" | "image" | "brokenImage" | "brokenImageContainer" | "noImageMessageContainer" | "noImageMessage" | "hover";

const styles = (stiboTheme: any) =>
  createStyles({
    card: {
      display: "flex",
      flex: 1,
      height: "32rem",
      boxShadow: "none"
    },
    image: {
      display: "block",
      borderRadius: "4px",
      maxHeight: "32rem",
      maxWidth: "100%",
      height: "auto",
      width: "auto",
      margin: "auto",
    },
    brokenImage: {
      height: "35%",
      width: "35%",
      fill: stiboTheme.palette.grey[300]
    },
    brokenImageContainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    noImageMessageContainer: {
      width: "99%",
      height: "99%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid",
      borderRadius: "4px",
      color: stiboTheme.palette.black[100],
      minHeight: "200px",
      minWidth: "200px"
    },
    noImageMessage: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans, Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif",
      fontSize: "20px",
      lineHeight: "1em",
      fontWeight: 500,
      textAlign: "center",
      color: stiboTheme.palette.black[300],
      display: "block"
    },
    hover: {
      cursor: "pointer"
    }
  });

export interface ProductImageProps extends StandardProps<React.SelectHTMLAttributes<HTMLDivElement>, ProductImageClasses> {
  image: Image;
  hoverable?: boolean;
  isFullScreenPreview?: boolean;
}

interface ProductImageState {
  imageOk: boolean;
}

class ProductImage extends React.Component<ProductImageProps & WithStiboStyles<ProductImageClasses>, ProductImageState> {
  constructor(props: ProductImageProps & WithStiboStyles<ProductImageClasses>) {
    super(props);

    this.state = {
      imageOk: true
    };
  }

  onImageLoadError = () => {
    this.setState({ imageOk: false });
  };

  render() {
    const { image, className: classNameProp, classes, isFullScreenPreview, hoverable, ...rest } = this.props;

    const { url } = image;
    let imageElement: JSX.Element = (
      <div className={classes.brokenImageContainer} title="File type not supported">
        <BrokenImageIcon className={classes.brokenImage} />
      </div>
    );

    if (image === EmptyImage) {
      imageElement = (
        <div className={classes.noImageMessageContainer}>
          <EmptyImageMessage className={classes.noImageMessage} />
        </div>
      );
    } else if (this.state.imageOk) {
      imageElement = <img onError={this.onImageLoadError} className={classNames(classes.image, { [classes.hover]: hoverable })} src={url} id={`productImage_Content`} />;
    }

    return (
      <Card className={classNames(classes.card, classNameProp)} data-name="content" id={`productImage_Card`} {...rest}>
        {imageElement}
      </Card>
    );
  }
}

export default withStiboStyles(styles)(ProductImage);
